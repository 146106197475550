import classNames from 'classnames/bind';
import styles from './Input.module.scss';

interface Props {
  name: string;
  value: string;
  label: string;
  type: string;

  className?: string;
  placeholder?: string;
  isError?: boolean;
  testId?: string;
  errorMessage?: string;

  onChange: (e: React.FormEvent<HTMLInputElement>) => any;
}
let cx = classNames.bind(styles);

export function Input({
  name,
  value,
  label,
  type = 'text',
  isError = false,
  className = '',
  placeholder = '',
  errorMessage = '',
  testId = 'Input',
  onChange,
}: Props) {
  return (
    <div
      className={`${cx({ Container: true, Error: isError })} ${className}`}
      data-testid={`${testId}Container`}
    >
      <label className={styles.Label} data-testid={`${testId}Label`} htmlFor={name}>
        {label}
      </label>
      <input
        className={styles.Input}
        data-testid={testId}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
      />
      {isError && (
        <span className={styles.ErrorMessage} data-testid={`${testId}Error`}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
