import styles from './SelectionCard.module.scss';

interface Props {
  isSelected: boolean;
  onClick: () => void;
  name: string;
  description: string;
  icon: string;
  testId?: string;
  className?: string;
}

export function SelectionCard({
  isSelected,
  onClick,
  name,
  description,
  icon,
  testId = 'SelectionCard',
  className = '',
}: Props) {
  return (
    <div
      className={`${styles.Card} ${isSelected ? styles.Active : ''} ${className}`}
      data-testid={testId}
      onClick={onClick}
    >
      <img alt={description} className={`${styles.Icon}`} src={icon} />
      <div className={styles.Info}>
        <p className={styles.Name} data-testid={`${testId}Name`}>
          {name}
        </p>
        <p className={styles.Desc} data-testid={`${testId}Description`}>
          {description}
        </p>
      </div>
      <div className={`${styles.Select} ${isSelected && styles.Active}`} />
    </div>
  );
}
