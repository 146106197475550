import { isBefore, parseISO, isValid } from 'date-fns';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { AuthSchema } from '../../adapters/auth';
import { appState } from '../../atoms/app';
import { authState } from '../../atoms/auth';
import { Loading } from '../../elements/Loading';

export function Setup() {
  const [, setApp] = useRecoilState(appState);
  const [, setAuth] = useRecoilState(authState);
  useEffect(() => {
    const currentUser = localStorage.getItem('Auth.currentUser');
    if (!currentUser) {
      setApp(a => {
        return { ...a, isSetup: true };
      });
    } else {
      const _c = JSON.parse(currentUser) as AuthSchema;
      const expiresAt = parseISO(_c.expiresAt);
      if (isValid(expiresAt) && isBefore(new Date(), expiresAt)) {
        setAuth(_c);
      }
      setApp(a => {
        return { ...a, isSetup: true };
      });
    }
  }, [setApp, setAuth]);
  return <Loading />;
}
