import { ReviewTaskSchema } from '../../../adapters/reviewTask';
import styles from './ReviewEmailCard.module.scss';
import { EmailCard } from '../EmailCard';
import { Status } from '../../../elements/Status';

interface Props {
  body: ReviewTaskSchema['body'];
  ccs: ReviewTaskSchema['ccs'];
  receivers: ReviewTaskSchema['receivers'];
  sender: ReviewTaskSchema['sender'];
  subject: ReviewTaskSchema['subject'];
  previousEmails: ReviewTaskSchema['previousEmails'];
}
export function ReviewEmailCard({ body, ccs, receivers, sender, subject, previousEmails }: Props) {
  const latestEmail = previousEmails[0];
  const t_latestEmail = (
    <>
      {latestEmail.intentClassification && (
        <div className={styles.ClassificationText}>
          Lead reply has intent classified as{' '}
          <Status label={latestEmail.intentClassification.split('_').join(' ')} />
        </div>
      )}
      <EmailCard
        body={latestEmail.body}
        ccs={latestEmail.ccs}
        className={styles.BodyContainer}
        intentClassification={latestEmail.intentClassification}
        receivers={latestEmail.receivers}
        sender={latestEmail.sender}
      />
    </>
  );

  return (
    <div className={styles.Container}>
      <div className={styles.Subject}>{subject}</div>
      <EmailCard
        body={body}
        ccs={ccs}
        className={styles.TaskBodyContainer}
        receivers={receivers}
        sender={sender}
        testId="ReviewEmailCard__toScore"
      />
      {t_latestEmail}
    </div>
  );
}
