import { IntentSchema } from '../../adapters/intent';
import { Prediction } from '../../adapters/task';
import { SelectionCard } from '../../elements/SelectionCard';

interface Props {
  predictionObject: IntentSchema;
  selectedPrediction: string | null;
  updatePrediction: (prediction: Prediction) => void;
}

export function PredictionCard({ predictionObject, selectedPrediction, updatePrediction }: Props) {
  const { code, name, description, icon } = predictionObject;
  const isSelected = code === selectedPrediction;

  return (
    <SelectionCard
      description={description}
      icon={icon}
      isSelected={isSelected}
      name={name}
      onClick={() => updatePrediction(code)}
      testId="PredictionCard"
    />
  );
}
