import { useState } from 'react';
import { Button } from '../../../elements/Button';
import styles from './ScoringContainer.module.scss';
import { SelectionCard } from '../../../elements/SelectionCard';
import { TextArea } from '../../../elements/TextArea';
import { isEmpty } from 'lodash';

interface Props {
  handleSubmitTask: (isCorrect: boolean, reason?: string) => void;
  isLoading?: boolean;
}

export function ScoringContainer({ handleSubmitTask, isLoading }: Props) {
  const [reason, setReason] = useState<string>('');
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null); //default to null when no selection made yet
  const submitDisabled =
    isCorrect === null || (isCorrect === false && isEmpty(reason)) || isLoading;

  const submitTask = () => {
    if (!submitDisabled) {
      handleSubmitTask(isCorrect, reason);
    }
  };

  return (
    <div className={styles.Container} data-testid="ScoringContainer">
      <div className={styles.Content}>
        <div className={styles.Options}>
          <SelectionCard
            className={styles.Card}
            description="This generation is correct"
            icon="https://static.saleswhale.com/dolphin/images/positive_intent.svg"
            isSelected={!!isCorrect}
            name="Correct"
            onClick={() => setIsCorrect(true)}
            testId="ScoringContainer__correctSelectionCard"
          />
          <SelectionCard
            className={styles.Card}
            description="This generation is incorrect"
            icon="https://static.saleswhale.com/dolphin/images/negative_intent.svg"
            isSelected={isCorrect === false}
            name="Incorrect"
            onClick={() => setIsCorrect(false)}
            testId="ScoringContainer__incorrectSelectionCard"
          />
        </div>
        <TextArea
          className={styles.TextAreaContainer}
          inputClassName={styles.TextArea}
          name="reason"
          onChange={e => setReason(e.currentTarget.value)}
          placeholder="Enter reason for selection"
          testId="ScoringContainer__reason"
          value={reason}
        />
        <div className={styles.ActionButtons}>
          <Button
            disabled={submitDisabled}
            label="Submit"
            onClick={submitTask}
            testId="ScoringContainer__submit"
          />
        </div>
      </div>
    </div>
  );
}
