import { getClass } from '../../utils/getClass';
import './Status.scss';

export interface StatusProps {
  variant?: string;
  label: string;
  theme?: 'dark';
  color?: 'green' | 'red' | 'blue' | 'yellow' | 'purple' | 'neutral' | 'orange' | 'transparent';
  left?: number;
  right?: number;
  testId?: string;

  onClick?: () => void;
}

export const Status = ({
  variant,
  label,
  theme,
  color = 'orange',
  left,
  right,
  testId = 'Status',
  onClick,
}: StatusProps) => {
  const customStyle = {
    marginLeft: left,
    marginRight: right,
  };

  return (
    <div
      className={getClass('Status', { theme, color }, variant)}
      data-testid={testId}
      onClick={onClick}
      style={customStyle}
    >
      {label}
    </div>
  );
};
