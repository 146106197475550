import React, { useRef } from 'react';
import styles from './EmailCard.module.scss';
import { htmlSanitizerAllowAttrs, parseHtmlString } from '../../../utils/formatting';
import { isEmpty } from 'lodash';
import { ReviewTaskSchema } from '../../../adapters/reviewTask';

interface Props {
  body: ReviewTaskSchema['body'];
  ccs: ReviewTaskSchema['ccs'];
  receivers: ReviewTaskSchema['receivers'];
  sender: ReviewTaskSchema['sender'];
  intentClassification?: ReviewTaskSchema['intentClassification'];
  className?: string;
  testId?: string;
}
export function EmailCard({
  body,
  ccs,
  receivers,
  sender,
  className,
  testId = 'EmailCard',
}: Props) {
  const emailRef = useRef<HTMLDivElement>(null);

  const t_html = parseHtmlString(body);
  for (const style of t_html.getElementsByTagName('style')) {
    style.remove();
  }
  const elements = t_html.getElementsByTagName('*');
  elements &&
    Array.from(elements).forEach(el => {
      el.removeAttribute('style');
    });
  const blockquote = t_html.querySelector('blockquote');
  blockquote?.remove();

  return (
    <div className={styles.EmailContainer}>
      <div className={styles.HeaderContainer}>
        <div className={styles.Details}>
          <div className={styles.SenderName}>From: {sender}</div>
          <div className={styles.Receivers}>To: {receivers.join(', ')}</div>
          {!isEmpty(ccs) && <div className={styles.Ccs}>cc: {ccs.join(', ')}</div>}
        </div>
      </div>
      <div
        className={`${styles.Body} ${className ? className : ''}`}
        dangerouslySetInnerHTML={{ __html: htmlSanitizerAllowAttrs(t_html.body.innerHTML) }}
        data-testid={testId}
        ref={emailRef}
      />
    </div>
  );
}
