import classNames from 'classnames/bind';
import styles from './Button.module.scss';

interface Props {
  label: string;
  testId?: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  variant?: string;
  onClick?: () => void;
}

const cx = classNames.bind(styles);

export function Button({
  label,
  type = 'button',
  disabled = false,
  variant,
  onClick,
  testId = 'Button',
}: Props) {
  return (
    <button
      className={classNames(cx({ Button: true }), variant)}
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {label}
    </button>
  );
}
