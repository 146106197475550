import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentTask } from '../../adapters/task';
import { Button } from '../../elements/Button';
import { Loading } from '../../elements/Loading';
import bg from '../../assets/images/bg-dashboard.png';
import styles from './Dashboard.module.scss';
import { currentUserName, currentUserRole } from '../../atoms/auth';
import { useRecoilValue } from 'recoil';
import { getCurrentReviewTask } from '../../adapters/reviewTask';
import { didError } from '../../elements/Toast';

export function Dashboard() {
  const [isShowingTaskChoice, setIsShowingTaskChoice] = useState(false);
  const [isLoading, setIsLoadingState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const name = useRecoilValue(currentUserName);
  const role = useRecoilValue(currentUserRole);

  useEffect(() => {
    if (!location.pathname.includes('dashboard')) {
      navigate('/dashboard', { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleStartSessionClick = () => {
    role === 'admin'
      ? setIsShowingTaskChoice(true)
      : role === 'ai_email_reviewer'
      ? startReviewTasks()
      : startClassificationTasks();
  };

  const startClassificationTasks = async () => {
    setIsLoadingState(true);
    try {
      const task = await getCurrentTask();
      if (task) {
        navigate(`/classification_tasks/${task.id}`);
      } else {
        navigate('/pending');
      }
    } catch (e) {
      // The API returns 404s if there's no task. Fixed in the next release so revert this in a few days
      navigate('/pending');
      // NegativeToast(e instanceof EngageAPIError ? e.errors : 'Something went wrong');
    } finally {
      setIsLoadingState(false);
    }
  };

  const startReviewTasks = async () => {
    setIsLoadingState(true);
    try {
      const task = await getCurrentReviewTask();
      if (task) {
        navigate('/review_tasks');
      } else {
        navigate('/review_pending');
      }
    } catch (e) {
      didError(e);
      navigate('/dashboard');
    } finally {
      setIsLoadingState(false);
    }
  };

  if (isLoading) return <Loading />;
  return (
    <div className={styles.Container} data-testid="Dashboard">
      <div className={styles.Left}>
        <div className={styles.Description}>
          <h1 className={styles.Title}>Howdy, {name}!</h1>
          <span className={styles.Subtitle}>
            Welcome to Saleswhale Operator! By joining Saleswhale, you are now part of a very
            special family seeking to rapidly build a world-class human-in-the-loop AI sales
            assistant that serves hundreds of delighted customers world-wide. As an Operator, you
            help to run our core business logic by qualifying replies, thereby generating examples
            for the AI to train on. Ready?
          </span>
          {isShowingTaskChoice ? (
            <div>
              <Button
                label="Intent classification tasks"
                onClick={startClassificationTasks}
                variant={styles.Button}
              />
              <Button
                label="Email review tasks"
                onClick={startReviewTasks}
                variant={styles.Button}
              />
            </div>
          ) : (
            <Button
              label="Start your session"
              onClick={handleStartSessionClick}
              variant={styles.Button}
            />
          )}
        </div>
      </div>
      <div className={styles.Right}>
        <img alt="welcome logo" src={bg} />
      </div>
    </div>
  );
}
