import { fetch } from 'whatwg-fetch';
import { get } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { ENDPOINT } from '../config/constants';
import { EngageAPIError } from '../adapters/error';
import { useEffect, useState } from 'react';

interface FetchParams {
  headers?: object;
  method?: string;
  body?: string;
}

const base = ENDPOINT; //here for convenience, will refactor in the future

export async function parseJSON(response: Response): Promise<object> {
  const reloadErrors = [401];
  if (reloadErrors.includes(response.status)) {
    //If there's an auth error we clear the session and reload
    localStorage.removeItem('Auth.currentUser');
    window.location.reload();
  }

  try {
    let data;
    const contentType = response.headers.get('Content-Type');
    if (
      (contentType && contentType.toLowerCase().includes('application/json')) ||
      contentType?.toLowerCase().includes('application/vnd.api+json')
    ) {
      data = await response.json();
      data = camelcaseKeys(data, { deep: true });
    } else {
      data = await response.text();
    }
    if (!response.ok) {
      if (!data?.errors) {
        data = { errors: [{ detail: 'Something went wrong' }] };
      }
      throw new EngageAPIError(data);
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function request(url: string, request: FetchParams, token?: string): Promise<object> {
  const authData = localStorage.getItem('Auth.currentUser');

  if (authData && !token) {
    const d = JSON.parse(authData);
    token = get(d, 'token');
  }

  request.headers = {
    accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : null,
    ...(request.headers || {}),
  };

  return fetch(`${base}${url}`, request).then(parseJSON);
}
export function getJSON(url: string): Promise<any> {
  return request(url, { method: 'GET' });
}
export function post(url: string, body = {}, token?: string): Promise<any> {
  return request(
    url,
    {
      method: 'POST',
      body: JSON.stringify(snakeCaseKeys(body, { deep: true })),
    },
    token
  );
}
export function regPost(url: string, body = {}, token?: string): Promise<any> {
  return request(
    url,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    token
  );
}
export function patch(url: string, body = {}): Promise<any> {
  return request(url, {
    method: 'PATCH',
    body: JSON.stringify(snakeCaseKeys(body, { deep: true })),
  });
}
export function put(url: string, body = {}): Promise<any> {
  return request(url, {
    method: 'PUT',
    body: JSON.stringify(snakeCaseKeys(body, { deep: true })),
  });
}
export function destroy(url: string, body = {}): Promise<any> {
  return request(url, {
    method: 'DELETE',
    body: JSON.stringify(snakeCaseKeys(body, { deep: true })),
  });
}
export async function fileRequest(
  url: string,
  request: FetchParams = {},
  token?: string
): Promise<object> {
  const authData = localStorage.getItem('Auth.currentUser');

  if (authData && !token) {
    const d = JSON.parse(authData);
    token = get(d, 'token');
  }

  request.headers = {
    accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : null,
    ...(request.headers || {}),
  };

  return fetch(`${base}${url}`, request).then(async (r: Response) => ({
    blob: await r.blob(),
    headers: r.headers,
  }));
}

type LoadableStatus = 'isLoading' | 'hasValue' | 'hasError' | 'isInitializing';

export interface APIResponse<T = any> {
  status: LoadableStatus;
  data: T | undefined;
  error: Error | EngageAPIError | unknown;
}

export function useGet(url: string | null) {
  const [response, setResponse] = useState<APIResponse>({
    status: 'isInitializing',
    data: undefined,
    error: undefined,
  });

  useEffect(() => {
    if (!url || response.status !== 'isInitializing') return;
    (async () => {
      try {
        setResponse(r => {
          return { ...r, status: 'isLoading' };
        });
        const data = await getJSON(url);
        setResponse(r => {
          return { ...r, status: 'hasValue', data };
        });
      } catch (error) {
        setResponse(r => {
          return { ...r, status: 'hasError', error };
        });
      }
    })();
  }, [url, response.status]);
  return response;
}
