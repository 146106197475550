import styles from './MainNav.module.scss';
import logo from '../../assets/images/logo-inverted.png';
import caret from '../../assets/images/caret.svg';
import statShiftPng from '../../assets/images/stat-shift.png';
import statYesterdayPng from '../../assets/images/stat-yesterday.png';
import { Avatar } from '../Avatar';
import { authState } from '../../atoms/auth';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../../adapters/auth';

export function MainNav() {
  const [currentUser, setCurrentUser] = useRecoilState(authState);
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut();
    setCurrentUser({
      id: 0,
      email: '',
      name: '',
      yesterdayTasks: 0,
      token: '',
      isAuthenticated: false,
      shiftTasks: 0,
      expiresAt: '',
      role: 'normal',
    });
    localStorage.removeItem('Auth.currentUser');
    navigate('/login');
  };

  return (
    <nav className={styles.Nav}>
      <div className={styles.Title}>Operator</div>
      <div className={styles.Logo}>
        <img alt="logo" src={logo} />
      </div>
      <div className={styles.Sidebar}>
        <div className={styles.SidebarHeader}>
          <Avatar name={currentUser.name} />
          <div className={styles.SidebarName}>
            <span style={{ fontWeight: '700' }}>{currentUser.name}</span>
            <span style={{ fontWeight: '500' }}>{currentUser.shiftTasks} tasks completed</span>
          </div>
          <img alt="caret" className="img--xs" src={caret} />
        </div>
        <div className={styles.SidebarContent}>
          <div className={styles.StatsContainer}>
            <h3>Your stats</h3>
            <div className={styles.Stats}>
              <div className={styles.Stat}>
                <img alt="stat-shift" className={styles.StatLogo} src={statShiftPng} />
                <div className={styles.Info}>
                  <span className={styles.InfoNumber}>{currentUser.shiftTasks}</span>
                  <span className={styles.InfoDay}>This shift</span>
                </div>
              </div>
              {currentUser.yesterdayTasks >= 0 && ( //investigate why attribute not included in response
                <div className={`${styles.Stat} ${styles.Last}`}>
                  <img
                    alt="stat-shiyesterdayft"
                    className={styles.StatLogo}
                    src={statYesterdayPng}
                  />
                  <div className={styles.Info}>
                    <span className={styles.InfoNumber}>{currentUser.yesterdayTasks}</span>
                    <span className={styles.InfoDay}>Yesterday</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {currentUser.role === 'admin' && (
            <div className={styles.Action} onClick={() => navigate('/dashboard')}>
              Back to dashboard
            </div>
          )}
          <div className={styles.Action} onClick={handleLogout}>
            <span>Sign out</span>
          </div>
        </div>
      </div>
    </nav>
  );
}
