import { atom, selector } from 'recoil';

export const appState = atom({
  key: 'App.state',
  default: {
    isSetup: false,
  },
});
export const isAppSetup = selector({
  key: 'App.isSetup',
  get: ({ get }) => {
    const app = get(appState);
    return app.isSetup;
  },
});
