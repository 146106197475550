import styles from './Loading.module.scss';

interface Props {
  className?: string;
  testId?: string;
  overlaid?: boolean;
}

export function Loading({ className, testId = 'Loading', overlaid }: Props) {
  if (overlaid) {
    return (
      <div className={styles.Overlay}>
        <div className={`${styles.Loading} ${className}`} data-testid={testId} />
      </div>
    );
  }
  return <div className={`${styles.Loading} ${className}`} data-testid={testId} />;
}
