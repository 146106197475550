import camelcaseKeys from 'camelcase-keys';
import { get } from 'lodash';
import { HOST } from '../config/constants';

export async function authWithEmail(email: string, password: string, otp: string) {
  const request = {
    headers: {
      accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ operator: { email, password, otp } }),
    method: 'POST',
  };
  const response = await fetch(`${HOST}/operators/sign_in`, request);
  const r = camelcaseKeys(await response.json(), {
    deep: true,
  }) as TokenAPISchema;
  if (!response.ok) {
    throw r;
  }
  const { operator, token, code } = r.status;
  if (code !== 'ok') {
    // eslint-disable-next-line no-throw-literal
    throw { error: 'Authentication failed. Please try again' };
  }
  return { ...operator, token, shiftTasks: 0 } as AuthSchema; //22-7-2022: TODO Figure out where shiftTasks is taken from
}

/**
 * This is where the call to sign out would live... IF IT EXISTED
 * 22-07-22: TODO: this should exist at some point
 */
// export function deleteSignOut() {
//   return true;
// }
export async function signOut() {
  const authData = localStorage.getItem('Auth.currentUser')!;
  const d = JSON.parse(authData);
  const token = get(d, 'token');

  const request = {
    headers: {
      accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: token && `Bearer ${token}`,
    },
    method: 'DELETE',
  };
  await fetch(`${HOST}/operators/sign_out`, request);
}

interface TokenAPISchema {
  status: {
    code: string;
    message: string;
    operator: {
      email: string;
      id: number;
      name: string;
      yesterdayTasks: number;
      role: 'admin' | 'seasoned' | 'normal' | 'trainee' | 'ai_email_reviewer';
    };
    token: string;
  };
}

// interface TokenAPIErrorSchema {
//   error: string;
// }
export interface AuthSchema {
  id: number;
  email: string;
  name: string;
  yesterdayTasks: number;
  token: string;
  isAuthenticated: boolean;
  shiftTasks: number;
  role: 'admin' | 'seasoned' | 'normal' | 'trainee' | 'ai_email_reviewer';
  expiresAt: string; //ISO date string
}
