import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentTask } from '../../adapters/task';
import { Loading } from '../../elements/Loading';
import styles from './ClassificationPending.module.scss';
import alert from '../../assets/sounds/alert.ogg';

// 26-07-22 TODO: There's probably a cleaner way to write this function out
export function ClassificationPending() {
  const [isInitializing, setIsInitializing] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(async () => {
      const task = await getCurrentTask();
      if (task) {
        const audio = new Audio(alert);
        audio.play();
        document.title = '(1) Saleswhale Overmind';
        navigate(`/classification_tasks/${task.id}`);
      }
    }, 5 * 1000);
    return () => clearInterval(timer);
  }, [navigate]);
  useEffect(() => {
    setIsInitializing(true);
    document.title = 'Saleswhale Overmind';
    (async () => {
      try {
        const task = await getCurrentTask();
        if (task) {
          navigate(`/classification_tasks/${task.id}`);
        }
      } catch (e) {
        throw e;
      } finally {
        setIsInitializing(false);
      }
    })();
  }, [navigate]);

  if (isInitializing) return <Loading />;
  return (
    <div className={styles.Container}>
      <div>
        <h1>No annotation tasks!</h1>
        <p>Helping you fetch new tasks if available...</p>
      </div>
    </div>
  );
}
