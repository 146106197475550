import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { htmlSanitizer } from '../../utils/formatting';

import './Toast.scss';
import { EngageAPIError } from '../../adapters/error';

export function CustomToast({ message }: { message: string }) {
  return (
    <div
      className="Toastify__content"
      dangerouslySetInnerHTML={{ __html: htmlSanitizer(message) }}
      data-testid="ToastContent"
    />
  );
}

// TODO: tweak the timing and possibly animation
export const notify = (
  message: string | { message?: string; detail?: string }[],
  type: 'positive' | 'negative'
) => {
  const options = {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
  };
  let m = message;

  // Arrays are only passed in for errors
  if (Array.isArray(m)) {
    if (m[0]?.message || m[0]?.detail) {
      const t = m.map(({ message, detail }) => message || detail).join('\n');
      m = t;
    } else {
      m = 'An error occurred.';
    }
  }

  switch (type) {
    case 'positive':
      return toast.success(
        <div className="Toastify__container" data-testid="SuccessToast">
          <CustomToast message={m} />
        </div>,
        options
      );
    case 'negative':
      return toast.error(
        <div className="Toastify__container" data-testid="ErrorToast">
          <CustomToast message={m || 'An error occurred.'} />
        </div>,
        options
      );
  }
};

export const PositiveToast = (message: string | { message?: string; detail?: string }[]) => {
  notify(message, 'positive');
};
// TODO: fix this typing
export const NegativeToast = (message: string | { message?: string; detail?: string }[] | any) => {
  notify(message, 'negative');
};

/**
 * Convenience wrapper to throw a NegativeToast if something errors
 * @param e usually an Error
 * @param fallback fallback string, defaults to 'An error occured'
 */
export function didError(e: unknown, fallback = 'An error occured') {
  if (e instanceof EngageAPIError) {
    NegativeToast(e.errors);
  } else {
    NegativeToast(fallback);
  }
}
