import { Navigate, useNavigate } from 'react-router-dom';
import { postReviewTaskScore, useGetReviewTask } from '../../adapters/reviewTask';
import { ReviewEmailCard } from './ReviewEmailCard';
import { Loading } from '../../elements/Loading';
import styles from './ReviewTask.module.scss';
import { ScoringContainer } from './ScoringContainer';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { authState } from '../../atoms/auth';
import { didError } from '../../elements/Toast';

export function ReviewTask() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const [_currentUser, setCurrentUser] = useRecoilState(authState);
  const { status: taskStatus, data: task } = useGetReviewTask();

  if (taskStatus === 'hasError') return <Navigate replace to="/review_pending" />;
  if (taskStatus === 'isLoading') return <Loading />;
  if (!task) return null;

  const handleSubmitTask = async (isCorrect: boolean, reason?: string) => {
    setIsSubmitting(true);
    try {
      await postReviewTaskScore({ reviewTaskId: task.id, isCorrect, reason });
      setCurrentUser(u => {
        return { ...u, shiftTasks: u.shiftTasks + 1 };
      });
      navigate('/review_pending');
    } catch (e) {
      didError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.Container}>
      <ReviewEmailCard
        body={task.body}
        ccs={task.ccs}
        previousEmails={task.previousEmails}
        receivers={task.receivers}
        sender={task.sender}
        subject={task.subject}
      />
      <ScoringContainer handleSubmitTask={handleSubmitTask} isLoading={isSubmitting} />
    </div>
  );
}
