import { useMemo } from 'react';
import { useGet, APIResponse } from '../services/fetch';
import { Prediction } from './task';

export function useGetIntents() {
  const response = useGet('/intents') as APIResponse<IntentApiSchema>;
  const { data, status } = response;
  const apiResponse = useMemo(() => {
    if (status !== 'hasValue' || !data) return [];
    return data.intents;
  }, [status, data]);
  return { ...response, data: apiResponse };
}

interface IntentApiSchema {
  intents: IntentSchema[];
}

export interface IntentSchema {
  code: Prediction;
  name: string;
  description: string;
  icon: string;
}
