import { getClass } from '../../utils/getClass';
import './Spinner.scss';

export interface SpinnerProps {
  className?: string;
  size?: 'small' | 'medium' | 'regular' | 'large';
  inline?: boolean;
}

export const Spinner = ({ className, size, inline }: SpinnerProps) => {
  const type = inline ? 'inline' : null;
  return (
    <div className={`${getClass('Spinner', { type, size })} ${className}`} data-testid="Spinner" />
  );
};
