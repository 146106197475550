import './App.scss';
import { Login } from './routes/Login';
import { ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Routes, Route } from 'react-router-dom';
import { Dashboard } from './routes/Dashboard';
import { ClassificationTask } from './routes/ClassificationTask';
import { ClassificationPending } from './routes/ClassificationPending';
import { ReviewPending } from './routes/ReviewPending';
import { isAppSetup } from './atoms/app';
import { Setup } from './routes/Setup';
import { authState } from './atoms/auth';
import { RootContainer } from './elements/RootContainer';
import { MainNav } from './elements/MainNav';
import { ReviewTask } from './routes/ReviewTask';

function App() {
  const isSetup = useRecoilValue(isAppSetup);
  const currentUser = useRecoilValue(authState);

  if (!isSetup) {
    return <Setup />;
  }

  const classificationRoutes = (
    <>
      <Route element={<ClassificationTask />} path="classification_tasks/:id" />
      <Route element={<ClassificationPending />} path="pending" />
    </>
  );

  const reviewRoutes = (
    <>
      <Route element={<ReviewTask />} path="review_tasks" />
      <Route element={<ReviewPending />} path="review_pending" />
    </>
  );

  const t_controlledRoutes =
    currentUser.role === 'admin' ? (
      <>
        {classificationRoutes}
        {reviewRoutes}
      </>
    ) : currentUser.role === 'ai_email_reviewer' ? (
      reviewRoutes
    ) : (
      classificationRoutes
    );

  const t_routes = currentUser.isAuthenticated ? (
    <>
      <MainNav />
      <RootContainer>
        <Routes>
          {t_controlledRoutes}
          <Route element={<Dashboard />} path="dashboard" />
          <Route element={<Dashboard />} path="*" />
        </Routes>
      </RootContainer>
    </>
  ) : (
    <Routes>
      <Route element={<Login />} path="*" />
    </Routes>
  );
  return (
    <div>
      <ToastContainer />
      {t_routes}
    </div>
  );
}

export default App;
