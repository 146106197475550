interface EngageError {
  id?: string;
  status?: string;
  title: string;
  detail: string;
  message?: string;
}
interface EngageErrors {
  errors: EngageError[];
}

/**
 * Errors thrown by the Engage API when something in the request went wrong.
 * This is specifically to handle errors unrelated to networks requests and when
 * the API actually is able to return a custom error object
 */
export class EngageAPIError extends Error {
  errors: EngageError[];
  constructor(engageErrors: EngageErrors) {
    const _message = engageErrors.errors.map(({ message, detail }) => message || detail).join(`\n`);
    super(_message);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, EngageAPIError);
    }

    this.name = 'APIError';
    this.errors = engageErrors.errors;
  }
}
