import { ChangeEvent, ReactNode } from 'react';
import './ToggleButton.scss';

interface Props {
  checked: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  testId?: string;
}

export function ToggleButton({
  children,
  className,
  label,
  required,
  checked,
  onChange,
  testId = 'ToggleButton',
  ...inputProps
}: Props) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };
  return (
    <div className={`ToggleButton ${className ? className : ''}`} data-testid={testId}>
      {label && (
        <div
          className={`ToggleButton__label ${required ? 'ToggleButton__label--required' : ''}`}
          data-testid="ToggleButton__label"
        >
          {label}
        </div>
      )}
      <div className="ToggleButton__inputWrapper">
        <label>
          <input
            {...inputProps}
            checked={checked}
            data-testid={`${testId}Input`}
            onChange={handleOnChange}
            required={required}
            type="checkbox"
          />
          <div className="ToggleButton__toggle" />
        </label>
        {children && <span className="ToggleButton__text">{children}</span>}
      </div>
    </div>
  );
}
