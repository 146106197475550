import { ChangeEventHandler, forwardRef } from 'react';
import styles from './TextArea.module.scss';

interface Props {
  className?: string;
  error?: boolean;
  inputClassName?: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  testId?: string;
  value?: string;
}
export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      className = '',
      inputClassName = '',
      error,
      name,
      onChange,
      placeholder,
      testId = 'TextArea',
      value,
      ...props
    }: Props,
    ref
  ) => {
    const textAreaProps = {
      className: `${styles.TextArea} ${error ? styles['TextArea--error'] : ''} ${inputClassName}`,
      'data-testid': testId,
      name,
      onChange,
      placeholder,
      value,
    };
    return (
      <div className={`InputField ${className}`} data-testid={`${testId}Container`} {...props}>
        <textarea {...textAreaProps} data-testid={testId} ref={ref} />
      </div>
    );
  }
);
