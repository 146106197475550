import { addHours } from 'date-fns';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { authWithEmail } from '../../adapters/auth';
import { authState } from '../../atoms/auth';
import { ENV } from '../../config/environment';
import { Button } from '../../elements/Button';
import { Input } from '../../elements/Input';
import { Spinner } from '../../elements/Spinner';
import { NegativeToast } from '../../elements/Toast';
import { emailValidation, inputValidation } from '../../utils/validation';
import styles from './Login.module.scss';

export function Login() {
  const [, setAuth] = useRecoilState(authState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otpToken, setOtpToken] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('login')) {
      navigate('/login', { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleLogin = async (email: string, password: string) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const auth = await authWithEmail(email, password, otpToken);
      const _auth = {
        ...auth,
        isAuthenticated: true,
        expiresAt: addHours(new Date(), 2).toISOString(),
      };
      setAuth(_auth);
      localStorage.setItem('Auth.currentUser', JSON.stringify(_auth));

      navigate('/dashboard');
    } catch (e: unknown) {
      NegativeToast((e as { error: string }).error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!(emailValidation(email) && inputValidation(password) && inputValidation(otpToken)))
      return setShowErrors(true);
    setShowErrors(false);
    handleLogin(email, password);
  };
  return (
    <div>
      <div className={styles.Nav}>
        <>
          <a href="/">
            <img
              alt="Saleswhale Logo"
              src="https://static.saleswhale.com/rep/images/sw-logo-full.svg"
            />
          </a>
        </>
      </div>
      <div className={styles.Container} data-testid="PasswordLoginContainer">
        {isLoading && <Spinner inline className={styles.Spinner} size="large" />}
        <div className={styles.FormContainer}>
          <h2 className={styles.Header}>Log In</h2>
          <div>
            <form noValidate className={styles.Form} onSubmit={handleFormSubmit}>
              <Input
                errorMessage="This doesn't look like an email address. Enter a valid email address and try again.
              "
                isError={showErrors && !emailValidation(email)}
                label="Email Address"
                name="email"
                onChange={e => {
                  setEmail(e.currentTarget.value);
                }}
                type="email"
                value={email}
              />
              <Input
                errorMessage="Please provide a password"
                isError={showErrors && password.length === 0}
                label="Password"
                name="password"
                onChange={e => {
                  setPassword(e.currentTarget.value);
                }}
                type="password"
                value={password}
              />
              <Input
                errorMessage="Please provide a token"
                isError={showErrors && otpToken.length === 0}
                label="Token"
                name="otpToken"
                onChange={e => {
                  setOtpToken(e.currentTarget.value);
                }}
                type="number"
                value={otpToken}
              />
              <Button label={'Log in'} type="submit" variant={styles.Button} />
            </form>
            <div className={styles.Links}>
              <a
                href={`https://brain.${ENV}.saleswhale.com/admin/login`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Setup token/change password
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
