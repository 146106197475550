import { useMemo } from 'react';
import { APIResponse, getJSON, post, useGet } from '../services/fetch';
import snakecaseKeys from 'snakecase-keys';

export async function getCurrentReviewTask() {
  const response = (await getJSON(`/review_task`)) as ReviewTaskAPISchema;
  if (!Array.isArray(response.data))
    return { ...response.data.attributes, id: response.data.id } as ReviewTaskSchema;
  return null;
}

export function useGetReviewTask() {
  const response = useGet('/review_task') as APIResponse<ReviewTaskAPISchema>;
  const { status, data, error } = response;
  const apiResponse = useMemo(() => {
    if (status !== 'hasValue' || !data) return null;
    return reviewTaskDecorator(data);
  }, [status, data]);

  return { status, data: apiResponse, error };
}

export async function postReviewTaskScore({
  reviewTaskId,
  isCorrect,
  reason,
}: {
  reviewTaskId: string;
  isCorrect: boolean;
  reason?: string;
}) {
  const payload = snakecaseKeys({
    data: {
      type: 'reviews',
      attributes: {
        reviewTaskId,
        isCorrect,
        reason,
      },
    },
  });
  return await post(`/reviews`, payload);
}

function reviewTaskDecorator(response: ReviewTaskAPISchema): ReviewTaskSchema {
  const included = response.included || [];

  const { data } = response;
  const { attributes, id } = data;
  const previousEmails = included.map(i => i.attributes);

  return {
    ...attributes,
    previousEmails,
    id,
  };
}

export interface ReviewTaskAPISchema {
  data: {
    attributes: {
      body: string;
      ccs: string[];
      receivers: string[];
      sender: string;
      subject: string;
      intentClassification: string;
    };
    relationships: {
      previousEmails: {
        data: { type: 'previous_emails'; id: string } | null;
      }[];
    };
    id: string;
    type: 'review_tasks';
  };
  included: {
    type: 'previous_emails';
    id: string;
    attributes: PreviousEmailSchema;
  }[];
}

interface PreviousEmailSchema {
  subject: string;
  body: string;
  ccs: string[];
  receivers: string[];
  sender: string;
  farewell: string;
  greeting: string;
  legal: string;
  intentClassification: string;
}

export interface ReviewTaskSchema {
  id: string;
  body: string;
  ccs: string[];
  receivers: string[];
  sender: string;
  subject: string;
  intentClassification: string;
  previousEmails: PreviousEmailSchema[];
}
